.page-contact {
    position: relative;
    display: block;
    padding: 12rem 1.5rem;
    @media(max-width: $sm) {
        padding: 6rem 1.5rem;
    }

    &-form {
        display: block;
        margin-bottom: 6rem;

        input[type='text'],
        input[type='email'],
        select,
        textarea {
            position: relative;
            display: block;
            padding: 1.5rem;
            width: 100%;
            margin-bottom: 3rem;
            border: none;
        }

        select {
            background-color: white;
            height: 5.4rem;
            border-radius: 0;
            color: gray;

            option {
                background-color: white;
            }
        }

        .rdoBtn {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 3rem;

            &_input {
                input[type="radio"]:checked + label {
                    font-weight: bold;
                }

                label {
                    display: inline-block;

                    input[type="radio"] {
                        display: inline-block;
                        margin: 0 0.5rem 0 1.5rem;
                    }
                }
            }
        }
    }
}
