//Colors
$main: #24B1CF !default;
// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #231f20 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0c3146 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #6d6d6d !default;
$gray-light:                #cccccc !default;
$gray-lighter:              #f4f4f4 !default;
$gray-lightest:             #fafafa !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $main !default;
$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

// Links
//
// Style anchor elements.

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: none !default;