//Page d'accueil
.homeCta {
  display: block;
  padding: 6rem 1.5rem;
  background-color: white;
  @media(max-width: $sm) {
    padding: 0rem 1.5rem 6rem 1.5rem;
  }
  img {
    display: block;
    width: auto;
    text-align: center;
    margin: 0 auto;
    @media(max-width: $sm) {
      margin-top: 6rem;
    }
  }
  h2 {
    font-size: 1.8rem;
    text-align: center;
    margin: 6rem 0 3rem 0;
    padding: 0 4.5rem;
  }
  .btn {
    display: block;
  }
}

.page-interne {
  display: block;
  padding: 6rem 0 6rem 0;
  @media(max-width: $sm) {
    padding: 6rem 1.5rem 3rem 1.5rem;
  }
  &-content {
    padding: 0 1.5rem;
    .pc-title {
      margin-top: 3rem;
      &:nth-child(2) {
        margin-top: 0;
      }
    }
    @media(max-width: $sm) {
      padding: 0;
    }
  }
}