// ***** Settings *****
@import 'base/settings';
@import 'base/colors';

// ***** Utils *****
@import 'utils/easings';
@import 'utils/animations';

//grid
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/reboot";
@import "bootstrap/mixins/breakpoints";
@import "bootstrap/mixins/grid-framework";
@import "bootstrap/mixins/grid";
@import "bootstrap/grid";

//tools
@import 'utils/media';
@import 'utils/responsive_tool';

//@import 'utils/spacer';
//@import 'utils/utilities';
// ***** Base *****
@import 'base/core';
@import 'base/typography';

// ***** Components *****
@import 'components/header';
@import 'components/footer';
@import 'components/button';
@import 'components/layout';
@import 'components/sidebar';
@import 'components/carousel';
@import 'components/proprietes';
@import 'components/courtier';
@import 'components/pagination';
@import 'components/banner';
@import 'components/contact';
@import 'components/login';
@import 'components/message-form';
@import 'components/quatre-cent-quatre';
@import 'components/multiselect';
@import 'components/search-cta';
@import 'components/socialmedia';
@import 'components/printstyle';
@import 'components/owlcarousel/core';
@import 'components/owlcarousel/owl.carousel'; //NEW
@import 'components/lightbox';
@import 'components/lightslider';
@import "components/custom";

select.searchForm-chambres,
select.searchForm-prix {
    display: block!important;
    height: 4rem;
    position: relative;
    width: 100%;
    text-align: left;
    border: 0;
    background-color: #fff;
    margin-top: 0.1rem;
    font-size: 1.3rem;
    color: #aaa;
    outline-offset: -.2rem;
    white-space: nowrap;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

.spacer-custom {
    display: block;
    height: 4.8rem;
    @media(max-width: $md) {
        height: 3rem;
    }
}
