.ms-options-wrap {
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    > button:focus,
    > button {
        position: relative;
        width: 100%;
        text-align: left;
        border: 0;
        background-color: #fff;
        padding: 1rem 1.5rem;
        margin-top: 1px;
        font-size: 13px;
        color: #aaa;
        outline-offset: -2px;
        white-space: nowrap;
    }

    > button {
        > span {
            display: inline-block;
        }

        &[disabled] {
            background-color: #e5e9ed;
            color: #808080;
            opacity: 0.6;
        }

        &:after {
            content: ' ';
            height: 0;
            position: absolute;
            top: 50%;
            right: 5px;
            width: 0;
            border: 6px solid rgba(0, 0, 0, 0);
            border-top-color: #999;
            margin-top: -3px;
        }
    }

    &.ms-has-selections > button {
        color: #333;
    }

    > .ms-options {
        position: absolute;
        left: 0;
        width: 100%;
        margin-top: 1px;
        margin-bottom: 20px;
        background: white;
        z-index: 2000;
        border: 1px solid #aaa;
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        border-top: 0;

        > .ms-search input {
            width: 100%;
            padding: 1rem 1.5rem;
            border: none;
            border-bottom: 1px groove;
            outline: none;
        }

        .ms-selectall {
            display: inline-block;
            font-size: 1.2rem;
            text-transform: lowercase;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        > .ms-selectall.global {
            margin: 4px 5px;
        }

        > ul > li.optgroup ul,
        > ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            font-size: 1.4rem;
        }

        > ul{
            li {
                position: relative;

                &:before{
                    content:'';
                    width:20px;
                    height:20px;
                    position: absolute;
                    left: 6px;
                    top: 7px;
                    z-index: 1;
                    border-radius: 4px;
                    border: 1px solid;
                    font-size: 0.75em;
                    line-height: 19px;
                    text-align: center;
                }

                &.ms-hidden {
                    display: none;
                }

                &.optgroup {
                    padding: 5px;

                    & + li.optgroup {
                        border-top: 1px solid #aaa;
                    }

                    .label {
                        display: block;
                        padding: 5px 0 0;
                        font-weight: bold;
                    }
                }

                &.selected{
                    &:before{
                        content: "\2713";
                        background-color: #24b1cf;
                        border-color: #24b1cf;
                        color:#fff;
                    }

                    label {
                        background-color: #efefef;
                        border-color: transparent;
                    }
                }
            }

            label {
                position: relative;
                display: inline-block;
                width: 100%;
                padding: 4px 4px 4px 33px;
                margin: 1px 0;
                border: 1px dotted transparent;
                text-align: left;

                &.focused,
                &:hover {
                    background-color: #efefef;
                    border-color: #999;
                }
            }

            input[type="checkbox"] {
                margin: 0 5px 0 0;
                position: absolute;
                left: 4px;
                top: 7px;
                visibility: hidden;
            }
        }

        &.hide-checkbox > ul input[type="checkbox"] {
            position: absolute !important;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
        }

    }

    &.ms-active > .ms-options {
        visibility: visible;
        opacity: 1;
    }
}
