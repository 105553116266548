//banner map
.banner-map{
  position: relative;
  margin-top: 10rem;
  display: block;
  width: 100%;
  min-height: 30rem;
  #map{
    position: relative;
    display: block;
    width: 100%;
    min-height: 30rem;
  }
}

//Banner top
.banner-top {
  position: relative;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6rem;
  width: 100%;
  min-height: 30rem;
  text-align: center;
  background-position: center;
  background-size: cover;
  @media(max-width: $md) {
    padding: 3rem;
    margin-top: 6rem;
  }
  &-content {
    position: relative;
    display: block;
    max-width: 60rem;
    z-index: 1;
    h1 {
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      margin: 0;
      @media(max-width: $sm) {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }
    h3 {
      margin: 0;
      font-size: 1.8rem;
      color: $gray-light;
      @media(max-width: $sm) {
        font-size: 1.6rem;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $black;
    opacity: 0.5;
    display: block;
  }
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}