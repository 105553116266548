.pagination {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 3rem 0;

    &:first-child{
        margin-top: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    li {
        display: flex;
        flex: 1;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.2rem;
        margin: 0;
        float: left;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 5rem;
            background-color: #eeeeee;
            transition: $anim-cubic $three-sec;

            &:hover {
                background-color: white;
            }
            @media(max-width: $md) {
                width: 100%;
            }

            i {
                line-height: 0;
                font-size: 1.8rem;
            }

            &.active {
                background-color: $main;
                color: white;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .pgItems {
        @media(max-width: $md) {
            &:not(.current){
                display: none;
            }
        }
    }
}
