.navbar {
  position: fixed;
  top: 0;
  background-color: white;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 1rem 1.5rem;
  transition: $anim-cubic $six-sec;
  @media(max-width: $md) {
    height: 6rem;
    padding: 0;
    // overflow: hidden;
  }
  &.sticky {
    transform: translateY(-4.4rem);
    @media(max-width: $md) {
      transform: none;
    }
  }
  ul {
    display: block;
    width: 75%;
    list-style: none;
    margin: 0;
    text-align: right;
    float: left;
    @media(max-width: $md) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
  &-main {
    @media(max-width: $md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 10rem;
      left: 0;
      width: 100%;
      padding: 3rem;
      height: 100%;
      float: none;
    }
    @media(max-height: 420px) {
      bottom: 0;
      right: -1.5rem;
      overflow: scroll;
      padding-right: 4.5rem;
    }
    li {
      display: inline-block;
      @media(max-width: $md) {
        display: block;
      }
      a {
        display: block;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        color: $black;
        @media(max-height: 420px) {
          padding: 0.5rem 1.5rem;
        }
        &:hover {
          color: $main;
        }
      }
    }
  }
  &-top {
    @media(max-width: $md) {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 3rem;
      width: 100%;
      padding: 0;
      z-index: 1;
      opacity: 0;
    }
    @media(max-height: 420px) {
      bottom: 1.5rem;
    }
    @media(max-height: 320px) {
      display: none !important;
    }
    &.active {
      opacity: 1;
      transition: $anim-cubic $three-sec;
      transition-delay: 0.4s;
    }
    li {
      display: inline-block;
      border-left: 0.1rem solid $gray-lighter;

      &:first-child {
        border: none;
      }
      a {
        display: block;
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        color: $gray-light;
        &:hover {
          color: $main;
        }
      }
    }
  }
  &-brand {
    display: block;
    width: 25%;
    float: left;
    transition: $six-sec $anim-cubic;
    padding: 0.5rem;
    transform-origin: left;
    &.sticky {
      transform: scale(0.5) translateY(5rem);
      @media(max-width: $md) {
        transform: none;
      }
    }
    img {
      width: 100%;
      max-width: 17rem;
    }
    @media(max-width: $md) {
      position: relative;
      width: auto;
      height: 6rem;
      padding: 0;
      z-index: 11;
      transform: none;
      img {
        width: auto;
        height: 6rem;
        padding: .5rem;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .navbar-nav {
    @media(max-width: $md) {
      position: fixed;
      top: 6rem;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: white;
      z-index: 10;
      height: 0%;
      transition: $anim-cubic $three-sec;
      overflow: hidden;
      &.open {
        height: 100%;
      }
    }
  }
  .burger-menu {
    position: relative;
    display: none;
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    float: right;
    border: none;
    background-color: $main;
    z-index: 11;
    transition: $anim-cubic $three-sec;
    &.active {
      background-color: $black;
      span {
        &:nth-child(1) {
          animation-name: burgerMenuFirst;
        }
        &:nth-child(2) {
          animation-name: burgerMenuMid;
          animation-delay: 0.1s;
        }
        &:nth-child(3) {
          animation-name: burgerMenuLast;
          animation-delay: 0.2s;
        }
      }
    }
    span {
      position: relative;
      display: block;
      width: 2.4rem;
      height: 0.2rem;
      margin: 0 auto;
      background-color: white;
      border-radius: 1px;
      transform-origin: left;
      animation-duration: 0.6s;
      animation-timing-function: $anim-cubic;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      &:nth-child(1) {
        animation-name: burgerMenuFirstReverse;
      }
      &:nth-child(2) {
        animation-name: burgerMenuMidReverse;
        animation-delay: 0.1s;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
      &:nth-child(3) {
        animation-name: burgerMenuLastReverse;
        animation-delay: 0.2s;
      }
    }
    @media(max-width: $md) {
      display: block;
    }
  }
}

.menuOpen {
  overflow: hidden !important;
}

@keyframes burgerMenuFirstReverse {
  100% {
    transform: translate(0, 0);
  }
  51% {
    transform: translate(6rem, 0) rotate(0deg);
  }
  50% {
    transform: translate(6rem, 6rem) rotate(45deg);
  }
  0% {
    transform: translate(0.25rem, 0.15rem) rotate(45deg);
  }
}

@keyframes burgerMenuMidReverse {
  to {
    transform: translateX(0rem);
  }
  from {
    transform: translateX(6rem);
  }
}

@keyframes burgerMenuLastReverse {
  100% {
    transform: translate(0, 0);
  }
  51% {
    transform: translate(6rem, 0) rotate(0deg);
  }
  50% {
    transform: translate(6rem, -6rem) rotate(-45deg);
  }
  0% {
    transform: translate(0.25rem, 0) rotate(-45deg);
  }
}

@keyframes burgerMenuFirst {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(6rem, 0) rotate(0deg);
  }
  51% {
    transform: translate(6rem, 6rem) rotate(45deg);
  }
  100% {
    transform: translate(0.25rem, 0.15rem) rotate(45deg);
  }
}

@keyframes burgerMenuMid {
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(6rem);
  }
}

@keyframes burgerMenuLast {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(6rem, 0) rotate(0deg);
  }
  51% {
    transform: translate(6rem, -6rem) rotate(-45deg);
  }
  100% {
    transform: translate(0.25rem, 0) rotate(-45deg);
  }
}