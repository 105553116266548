/*media*/
.square-zone {
	position: absolute;
	width: 100%;
	height: auto;
	z-index: 0;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-full {
	width: 100%;
	height: auto;
}
