//responsive breakpoints
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

//bootstrap settings
$enable-flex: false;
$enable-grid-classes: true;

//bootstrap grid
$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;