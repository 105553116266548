.hero {
    position: relative;

    .btn {
        background-color: white;
        color: $black;

        &:hover {
            background-color: $main;
            color: white;
            border-color: $main;
        }
    }

    .owl-nav {
        color: $black;
        z-index: 1;

        .owl-next,
        .owl-prev {
            position: absolute;
            top: 50%;
            padding: 0.5rem 2rem;
            background-color: rgba(255, 255, 255, 0.76);
            transition: $anim-cubic $three-sec;

            i {
                font-size: 4rem;
                line-height: 1;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .owl-prev {
            left: 0;
        }

        .owl-next {
            right: 0;
        }
    }

    .owl-dots {
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);

        .owl-dot {
            display: block;
            padding: 0 !important;
            background-color: rgba(255, 255, 255, 0.91);
            height: 1.3rem;
            width: 1.3rem;
            border-radius: 50%;
            margin: 1.5rem 0;
            border: 0.2rem solid white;

            &.active {
                background-color: $gray-light;
            }
        }
    }
    //Carousel principal
    .owl-item {
        &-fixe,
        &-content {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            z-index: 2;
            padding: 13.5rem 0 21.5rem;
            text-align: left;

            @media(max-width: $md) {
                padding-bottom: 13.5rem;
                padding-right: 6rem;
            }

            .container {
                position: relative;
                z-index: 1;

                h2 {
                    font-size: 5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.2;
                    margin-top: 0;
                    color: white;
                    @media(max-width: $sm) {
                        font-size: 5vw;
                    }
                }

                h3 {
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.2;
                    margin-top: 0;
                    color: white;
                    @media(max-width: $sm) {
                        font-size: 3vw;
                    }
                }

                .btn {
                    margin-top: 3rem;
                    display: inline-block;
                    pointer-events: auto;
                    cursor: pointer;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.25);
            }
        }

        &-fixe{
            position: absolute;
            top:0;
            z-index: 1;
            pointer-events: none;

            &:after {
                content: none;
            }
        }
    }
}
//Carousel Partenaire

#partnerSlider {
    list-style: none;
    padding: 0 1.5rem;
    margin: 6rem 0;

    li {
        a {
            img {
                display: block;
                width: 100%;
                opacity: 1;
                transition: $anim-cubic $three-sec;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .owl {
        &-nav {
            font-size: 6rem;
            color: $gray-light;
            @media(max-width: $sm) {
                position: relative;
                padding: 3rem;
                text-align: center;
                font-size: 1.4rem;
            }
        }

        &-next,
        &-prev {
            &:hover {
                color: $gray;
            }
        }

        &-prev {
            position: absolute;
            top: 8%;
            left: -4.5rem;
            @media(max-width: $sm) {
                position: relative;
                top: 0;
                left: 0;
                display: inline-block;
                font-size: 1.4rem;
                color: $black;

                &:after {
                    content: 'Prochain';
                    font-size: 1.4rem;
                    color: $black;
                    padding: 1.5rem;
                }
            }
        }

        &-next {
            position: absolute;
            top: 8%;
            right: -4.5rem;
            @media(max-width: $sm) {
                position: relative;
                top: 0;
                right: 0;
                display: inline-block;
                color: $black;

                &:before {
                    content: 'Suivant';
                    font-size: 1.4rem;
                    color: $black;
                    padding: 1.5rem;
                }
            }
        }
    }
}
//CAROUSEL PROP

#propSlider {
    margin: 0.5rem 0 0;
}

#carThumb {
    position: relative;
    display: block;
    padding: 1.5rem;

    .owl-item {
        opacity: 0.5;
        transition: $anim-cubic $three-sec;

        &:hover {
            opacity: 0.8;
        }

        &.active.center {
            opacity: 1;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .owl-nav {
        .owl-next,
        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: $anim-cubic $three-sec;
            color: $main;
            border: none;
            background-color: transparent;

            i {
                font-size: 3rem;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .owl-next {
            right: 0;
        }

        .owl-prev {
            left: 0;
        }
    }
}
/*lightbox carousel*/
#lgtCarContent {
    position: relative;
    display: block;
    max-width: 60rem;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    #lgtCarContent {
        .owl-nav {
            .owl-next,
            .owl-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: $anim-cubic $three-sec;
                color: white;

                i {
                    font-size: 3rem;
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            .owl-next {
                right: -3rem;
            }

            .owl-prev {
                left: -3rem;
            }
        }
    }
}

.owl-next,
.owl-prev {
    background-color: transparent;
    border: none;
    outline: none;
}
