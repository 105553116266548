.hero {
    .searchCta {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 3rem;
        z-index: 2;

        @media(max-width: $md) {
            position: relative;
            bottom: 0;
            margin-top: 6rem;
        }
        @media(max-height: 650px) {
            position: relative;
            bottom: 0;
            margin-top: 6rem;
        }
    }
}

.searchCta {
    position: relative;
    z-index: 5;

    &-content {
        position: relative;
        display: block;
        padding: 3rem 3rem 1.5rem;
        background-color: rgba(0, 0, 0, 0.75);

        input[type="text"] {
            display: block;
            border: none;
            width: 100%;
            padding: 1rem 1.5rem;
            font-size: 1.3rem;
            @media(max-width: $md) {
                margin-bottom: 1.5rem;
            }
        }

        input[type='submit'] {
            display: block;
            width: calc(50% - 0.75rem);
            float: left;
            padding: 1rem 0.5rem;
            border: none;
            background-color: $main;
            color: white;
            @media(max-width: $md) {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.searchForm-btn-search {
    margin-right: 1.5rem;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-align: left;
    font-size: 13px;
    color: #aaaaaa;
    white-space: nowrap;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: left;
    font-size: 13px;
    color: #aaaaaa;
    white-space: nowrap;
}

:-ms-input-placeholder {
    /* IE 10+ */
    text-align: left;
    font-size: 13px;
    color: #aaaaaa;
    white-space: nowrap;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: left;
    font-size: 13px;
    color: #aaaaaa;
    white-space: nowrap;
}
