//Animation time
$three-sec: .3s;
$six-sec: .6s;

//Animation curve
$animation-delay: 1.2s;
$duration: 1.5s;
$iterations: 1;

//Animation du theme
body.menuOpen{
  overflow: hidden;
}