.socialMediaShare {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  &:after{
    content: '';
    display: block;
    clear: both;
  }
  @media(max-width: $sm) {
    top: initial;
    bottom: 0;
    right: 0;
    left: 0;
    transform: none;
  }
  a {
    display: block;
    padding: 1.5rem;
    color: white;
    text-align: center;
    @media(max-width: $sm) {
      width: 25%;
      float: left;
    }
    &:hover {
      transform: scale(1.1);
      z-index: 3;
    }
  }
  &-facebook {
    background-color: #3B5998;
  }
  &-google {
    background-color: #DB4437;
  }
  &-linkedin {
    background-color: #007BB5;
  }
  &-twitter {
    background-color: #1DA1F2;
  }
}