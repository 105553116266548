//Init font
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  overflow-x: hidden;
  background-color: $gray-lighter;
  -webkit-font-smoothing: antialiased;
  opacity: 0;
  text-align: justify;

  &.loadFinish{
    opacity: 1;
    transition: $anim-cubic $six-sec;
  }
}
:hover{
  -webkit-font-smoothing: antialiased;
}

@media(max-width: $lg) {
    .page-interne-content{
        padding-right: 0;
    }
}
