.login {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $gray-lighter;
  z-index: 99;
  &-content {
    position: relative;
    display: block;
    min-width: 30rem;
    height: auto;
    padding: 3rem;
    img {
      display: block;
      max-width: 15rem;
      margin: 0 auto 3rem auto;
    }
    &-options {
      display: block;
      padding: 3rem;
      a {
        padding: .5rem;
        display: block;
        font-size: 1.4rem;
        color: $gray;
        &:hover {
          color: $black;
        }
      }
    }
    form {
      position: relative;
      display: block;
      width: 100%;
      max-width: 40rem;
      height: auto;
      padding: 3rem;
      background-color: white;
      box-shadow: 0 0 10rem -3rem rgba(0, 0, 0, 0.5);
      label {
        display: block;
        width: 100%;
        font-size: 1.4rem;
        color: $gray;
        span {
          display: block;
          margin-bottom: .5rem;
        }
        input {
          position: relative;
          display: block;
          padding: 1.5rem;
          width: 100%;
          margin-bottom: 3rem;
          border: none;
          background-color: $gray-lighter;
          &[type="checkbox"] {
            display: inline-block;
            width: auto;
            margin-right: 1.5rem;
            margin-bottom: 0;
          }
        }

      }
      .checkbox {
        display: inline-block;
        width: auto;
        padding: .7rem 0;
        span {
          display: inline-block;
          width: auto;
          line-height: 0;
          margin-right: 3rem;
        }
      }
      button {
        float: right;
        display: inline-block;
        font-size: 1.2rem;
        @media(max-width: 439px) {
          float: none;
        }
      }
    }
  }
}