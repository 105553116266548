aside {
    display: block;
    padding: 0;
    width: 100%;
    margin-bottom: 3rem;
    @media(max-width: $sm) {
        margin-top: 6rem;
    }

    section {
        padding: 3rem 0;
        // border-bottom: 0.5rem solid $gray-lighter;
        margin-bottom: .5rem;

        &:not(.sb-content){
            &:first-child {
                padding-top: 0;
            }
        }
    }

    .btn {
        display: block;
    }
}

.sb-content {
    padding: 5rem 3rem;
    text-align: center;
    background-color: white;

    i {
        font-size: 5rem;
        color: $main;
        margin-bottom: 3rem;
    }

    img {
        text-align: center;
        margin-bottom: 3rem;
        max-width: 10rem;
        width: 100%;
    }

    input, select {
        display: block;
        width: 100%;
        height: 30px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        text-align: left;
    }

    .btn,
    button {
        display: block;
        margin-top: 3rem;
        width: 100%;
    }

    .paiement,
    .pouvAchat,
    .tx-bienvenue{
        li{
            margin: 10px 0;

            .row{
                display: flex;
                align-items: center;
            }

            label{
                line-height: 1.1;
                margin: 0;
            }
        }
    }
}

.sb-title {
    display: block;
    margin: 0 0 2rem;
    text-align: center;
    text-transform: uppercase;

    &:first-child {
        margin-top: 0;
    }
}
