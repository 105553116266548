//CARD

.proprietes {
    position: relative;
    display: block;
    padding: 12rem 0;

    ul {
        list-style: none;
        padding: 0;
    }
    @media(max-width: $sm) {
        padding: 6rem 0;
    }

    .btn {
        display: block;
        width: auto;
        margin: 0 auto;
        @media(max-width: $sm) {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    &-card {
        position: relative;
        display: block;
        background-color: white;
        margin-bottom: 3rem;
        overflow: hidden;

        &-sale {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 20rem;
            padding: 1.5rem;
            color: white;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            background-color: $red;
            z-index: 1;
            transform: rotate(-45deg) translateX(-25%) translateY(-50%);
        }

        &-visite {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 20rem;
            padding: 1.5rem;
            color: white;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            background-color: $main;
            z-index: 1;
            transform: rotate(-45deg) translateX(-25%) translateY(-50%);
        }

        .prop-thumbs {
            position: relative;
            display: block;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                transform: scale(1.1);
                transition: $anim-cubic $three-sec;
            }

            .house-id {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, 0.71);
                padding: 1.5rem;
                color: $black;
                font-size: 1.2rem;
                text-transform: uppercase;
            }
        }

        .prop-content {
            display: block;
            padding: 3rem;
            color: $black;
            overflow: hidden;

            &-infos {
                position: relative;
                display: block;

                li {
                    position: relative;
                    display: block;

                    &:after {
                        content: '';
                        display: block;
                        clear: both;
                    }

                    &:nth-child(1) {
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
            }

            &-features {
                display: block;
                width: 40%;

                li {
                    display: block;
                    width: 50%;
                    float: left;
                }
            }
        }

        &:hover {
            img {
                transform: scale(1);
                opacity: 0.7;
            }
        }
    }
}
//Price section

.price {
    width: 50%;
    float: left;
    font-size: 1.8rem;
    font-weight: 700;
    color: $main;
}

.features {
    display: block;
    width: 50%;
    float: left;
    text-align: right;

    span {
        font-weight: 700;

        &:first-child {
            margin-right: 1.5rem;
        }
    }

    i {
        color: $gray;
        margin-left: 0.5rem;
    }
}
//CARD INTERNE Horizontale

.proprietes-cardH {
    position: relative;
    display: block;
    background-color: white;
    margin-bottom: 3rem;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    &-sale {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20rem;
        padding: 1.5rem;
        color: white;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: $red;
        z-index: 1;
        transform: rotate(-45deg) translateX(-25%) translateY(-50%);
    }

    &-visite {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20rem;
        padding: 1.5rem;
        color: white;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: $main;
        z-index: 1;
        transform: rotate(-45deg) translateX(-25%) translateY(-50%);
    }

    &.rebuild {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 1.5rem);
        margin: 0 1.5rem 3rem;
        background-color: transparent;

        @media(max-width: $md) {
            display: block;
            width: 100%;
            margin-left:0;
            margin-right:0;
        }

        &:nth-child(odd) {
            margin-right: 0;
        }
        &:nth-child(even) {
            margin-left: 0;
        }

        .proprietes-cardH-infos,
        .proprietes-cardH-thumb {
            display: block;
            width: 100%;
            float: none;
            background-color: white;
        }

        .proprietes-cardH-content {
            display: none;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .proprietes-cardR {
        display: block;
        position: relative;

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &-thumb {
        display: block;
        width: 45%;
        height: 30.8rem;
        float: left;
        background-size: cover;
        background-position: center;
        transition: $anim-cubic $three-sec;

        &:hover {
            opacity: 0.8;
        }
        @media(max-width: $md) {
            width: 100%;
            float: none;
        }
    }

    &-infos {
        position: relative;
        display: block;
        width: 55%;
        height: auto;
        float: left;
        padding: 3rem;
        font-size: 1.6rem;

        .btn {
            display: block;
        }
        @media(max-width: $md) {
            width: 100%;
            float: none;
            padding-bottom: 0;

            .btn {
                margin-top: 1.5rem;
            }
        }

        li {
            display: block;
            min-height: 6px;

            &:nth-child(1) {
                font-size: 1.8rem;
                color: $main;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 45px;
            }

            &:nth-child(4) {
                margin-bottom: 1.5rem;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }

            table {
                // display: block;
                width: 100%;
                text-align: left;

                tr {
                    display: block;
                    padding: 0.5rem 0;
                    border-top: 0.1rem solid $gray-light;

                    &:after {
                        content: '';
                        display: block;
                        clear: both;
                    }

                    &:last-child {
                        border-bottom: 0.1rem solid $gray-light;
                        margin-bottom: 1.5rem;
                    }

                    td {
                        width: 60%;
                        float: left;
                        font-weight: 700;

                        &:last-child {
                            width: 40%;
                            font-weight: normal;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    &-content {
        display: block;
        width: 100%;
        padding: 3rem;

        p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .zone {
        position: absolute;
        top: 3rem;
        right: 3rem;
        color: $main;

        i {
            font-size: 5rem;
        }

        span {
            position: absolute;
            top: 15%;
            left: 37%;
            font-weight: 700;
        }
    }
}
//sidebar card

.sidebar-single-prop{
    form{
        margin-bottom: 1.5rem;
    }
}

.sb-proprietes-card {
    position: relative;
    display: flex;
    background-color: white;
    margin-bottom: 3rem;
    transition: $anim-cubic $three-sec;

    @media(max-width: $md){
        display: block;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        opacity: 0.8;
    }

    &-thumb {
        display: block;
        width: 38%;
        background-size: cover;
        background-position: center;
        min-height: 10rem;

        @media(max-width: $md) {
            width: 100%;
        }
        @media(max-width: $xs) {
            height: 25rem;
        }
    }

    &-infos {
        list-style: none;
        display: block;
        width: 62%;
        padding: 1.5rem 2rem;
        color: $black;
        font-size: 1.4rem;
        margin-bottom: 0;
        align-self: center;
        text-align: left;

        @media(max-width: $md) {
            width: 100%;
        }

        li {
            &:nth-child(1) {
                font-size: 1.4rem;
                font-weight: 700;
                color: $main;
                text-transform: uppercase;
            }
        }
    }
}
//Single proprietes

.single-prop-details {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 1.5rem;
        }
    }

    strong {
        display: block;
    }

    .addenda {
        ul {
            list-style: auto;

            li {
                margin-bottom: auto;
            }

            strong {
                display: inline;
            }
        }
    }
}



.sinlge-prop-infos {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding-bottom: 3rem;
        border-bottom: 0.1rem solid $gray-light;
        margin-bottom: 3rem;
    }

    &-ville {
        font-size: 2rem;
        color: $main;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }

    &-prix {
        font-size: 3rem;
        font-weight: 700;
    }

    &-mls {
        font-weight: 700;
    }
}

.single-prop-table {
    position: relative;
    display: block;
    width: 100%;

    thead {
        display: block;
        background-color: $gray-light;
    }

    tbody,
    thead {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;

        tr {
            display: block;
            width: 100%;

            &:nth-child(even) {
                background-color: white;
            }

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            td,
            th {
                display: block;
                padding: 1.5rem;
                float: left;

                &:nth-child(1) {
                    width: 25%;
                }

                &:nth-child(2) {
                    width: 25%;
                }

                &:nth-child(3) {
                    width: 30%;
                }

                &:nth-child(4) {
                    width: 20%;
                }
            }
        }
    }
}
//Filter

.filter-prop {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.1rem solid $gray-light;
    margin-bottom: 3rem;
    padding-bottom: 3rem;

    &-number {
        width: 50%;
        text-align: left;
    }

    &-options {
        width: 50%;
        text-align: right;

        .viewFilter {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $main;
            margin-right: 1rem;
            //padding: 1rem 1.3rem;
            height: 4.46rem;
            width: 5rem;
            border: 0.2rem solid #ccc;
            background-color: transparent;
            transition: cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
            @media(max-width: $md) {
                display: none;
            }

            i {
                line-height: 0;
            }

            &:hover {
                color: white;
                background-color: #ccc;
            }
        }

        .filterBy {
            display: inline-block;
            vertical-align: top;

            form{
                margin: 0;
            }
        }
    }
}

.proprietes-detail {
    position: absolute;
    top: 0;
    left: 3rem;
    display: block;
    width: 13rem;
    height: 13rem;
    overflow: hidden;
    z-index: 2;
    @media(max-width: $sm) {
        left: 1.5rem;
    }
}

.page-interne-content.rebuild {
    font-size: 0;
}
