@media print {
    .btn-single-propriete,
    .footer-main,
    .navbar,
    .sb-content,
    .searchCta,
    .searchCta-content,
    .socialMediaShare {
        display: none !important;
    }

    .banner-map {
        margin-top: 0;
    }

    .single-propriete-details {
        position: relative;

        .col-lg-4 {
            order: 1;

            .single-prop-details-title {
                display: none !important;
            }
        }

        .col-lg-8 {
            order: 2;
        }

        .single-prop-details-block {

        }
    }

    .single-prop-details-title {
        page-break-inside: avoid;
    }

    .sb-courtier-card {
        display: flex;

        &-thumb{
            width: 40%;
            max-width: 150px;
            min-width: initial;
        }
        &-infos{
            width: 60%;
        }
    }
}
