//courtier card

.courtier-card-content {
    display: block;
    // margin-bottom: 3rem;
    border-bottom: 3rem solid $gray-lighter;
    background-color: white;
    min-height: 100%;
    text-align: left;

    img {
        width: 100%;
        height: auto;
    }

    a {
        color: $black;

        &:hover {
            opacity: 0.7;
        }
    }

    ul {
        list-style: none;
        padding: 3rem;
        margin: 0;

        li {
            font-size: 1.4rem;

            &:first-child {
                font-size: 1.6rem;
                margin-bottom: 0.5rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            &:nth-child(2) {
                margin-bottom: 0.5rem;
            }
        }
    }
}
//sidebar courtier card

.sb-courtier-card {
    position: relative;
    display: flex;
    background-color: white;
    margin-bottom: 3rem;
    transition: $anim-cubic $three-sec;

    @media(max-width: $md) {
        display: block;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-thumb {
        display: block;
        min-width: 38%;
        min-height: 13.5rem;
        background-size: cover;
        background-position: center;

        @media(max-width: $md) {
            width: 100%;
        }
        @media(max-width: $xs) {
            height: 25rem;
        }
    }

    &-infos {
        list-style: none;
        display: block;
        width: 62%;
        padding: 1.5rem 2rem;
        font-size: 1.4rem;
        margin-bottom: 0;
        align-self: center;

        @media(max-width: $md) {
            width: 100%;
        }

        li {
            &:nth-child(1) {
                font-size: 1.6rem;
                font-weight: 700;
                color: $main;
                text-transform: uppercase;
                margin-bottom: 1.5rem;

                a {
                    color: inherit;
                    white-space: normal;
                }
            }

            a {
                color: $black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
//PAGE COURTIER SINGLE

.page-courtier-single {
    &-thumb {
        img {
            width: 100%;
            height: auto;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-infos {
        position: relative;
        display: block;
        padding-left: 3rem !important;

        li {
            display: block;

            a {
                color: $black;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .phone {
            margin: 1.5rem 0;
        }

        .name {
            font-size: 1.8rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
        }

        .tel {
            position: relative;
            left: 2.5rem;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                font-family: Font Awesome\ 5 Pro;
                content: '\f095';
                margin: 0 5px 0 -2.5rem;
                color: $main;
            }
        }

        .fax {
            position: relative;
            left: 2.5rem;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                font-family: Font Awesome\ 5 Pro;
                content: '\f1ac';
                margin: 0 5px 0 -2.5rem;
                color: $main;
            }
        }

        .address {
            position: relative;
            left: 2.5rem;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                font-family: Font Awesome\ 5 Pro;
                content: '\f041';
                margin: 0 5px 0 -2.5rem;
                color: $main;
            }
        }
    }
}

.equipe-row {
    position: relative;
    margin-bottom: 6rem;

    h2 {
        margin-left: 1.5rem;
    }
}
//Page courtier single

.single-courtier.proprietes {
    padding: 0;
}

.single-courtier-infos {
    margin-bottom: 6rem;

    img {
        display: block;
        width: 100%;
        @media(max-width: $sm) {
            margin-bottom: 6rem;
        }
    }

    &-name {
        font-weight: 700;
        font-size: 2.5rem;
        text-transform: uppercase;
        color: $main;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            margin-bottom: 3rem;

            &:nth-last-child(2) {
                padding-left: 2.5rem;

                &.--location {
                    &:before {
                        content: '\f3c5';
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-family: "Font Awesome 5 Pro";
                        color: $main;
                    }
                }
            }

            &.--email {
                padding-left: 2.5rem;

                &:before {
                    content: '\f0e0';
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-family: "Font Awesome 5 Pro";
                    color: $main;
                }
            }
        }

        .single-courtier-infos-tel {
            position: relative;

            li {
                margin-bottom: 0;
                padding-left: 2.5rem;

                &.--tel {
                    &:before {
                        content: '\f095';
                        font-family: "Font Awesome 5 Pro";
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: $main;
                        display: inline-block;
                        width: 1.5rem;
                        text-align: center;
                    }
                }

                &.--cell {
                    &:before {
                        content: '\f3cd';
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-family: "Font Awesome 5 Pro";
                        color: $main;
                        display: inline-block;
                        width: 1.5rem;
                        text-align: center;
                    }
                }

                &.--fax {
                    &:before {
                        content: '\f1ac';
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-family: "Font Awesome 5 Pro";
                        color: $main;
                        display: inline-block;
                        width: 1.5rem;
                        text-align: center;
                    }
                }
            }
        }
    }
}
