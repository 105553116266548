/*POSITIONNEMENT TOOL*/
.pull-xs-left {
	float: left !important;
}

.pull-xs-right {
	float: right !important;
}

.pull-xs-none {
	float: none !important;
}

@media (max-width: 544px) {
	.pull-sm-left {
		float: left !important;
	}
	.pull-sm-right {
		float: right !important;
	}
	.pull-sm-none {
		float: none !important;
	}
}

@media (max-width: 768px) {
	.pull-md-left {
		float: left !important;
	}
	.pull-md-right {
		float: right !important;
	}
	.pull-md-none {
		float: none !important;
	}
}

@media (max-width: 992px) {
	.pull-lg-left {
		float: left !important;
	}
	.pull-lg-right {
		float: right !important;
	}
	.pull-lg-none {
		float: none !important;
	}
}

@media (max-width: 1200px) {
	.pull-xl-left {
		float: left !important;
	}
	.pull-xl-right {
		float: right !important;
	}
	.pull-xl-none {
		float: none !important;
	}
}


/*HIDDEN TOOL*/
.hidden-xs-up {
	display: none!important
}

@media (max-width:575px) {
	.hidden-xs-down {
		display: none!important
	}
}

@media (min-width:576px) {
	.hidden-sm-up {
		display: none!important
	}
}

@media (max-width:767px) {
	.hidden-sm-down {
		display: none!important
	}
}

@media (min-width:768px) {
	.hidden-md-up {
		display: none!important
	}
}

@media (max-width:991px) {
	.hidden-md-down {
		display: none!important
	}
}

@media (min-width:992px) {
	.hidden-lg-up {
		display: none!important
	}
}

@media (max-width:1199px) {
	.hidden-lg-down {
		display: none!important
	}
}

@media (min-width:1200px) {
	.hidden-xl-up {
		display: none!important
	}
}

.hidden-xl-down {
	display: none!important
}
