.searchCta-content > form > div.row {
    max-width: 1140px;
    margin: 0 auto;
}

.uppercase {
    text-transform: uppercase;
}

.alert.alert-danger {
    color: red;
}

.alert.alert-success {
    color: green;
}

select.form-control {
    padding: 1rem;
    margin-bottom: 15px;
    width: 100%;
    border: none;
}

.courtier-card-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 25rem;
}

.courtier-card-image>a {
    display: block;
    width: 100%;
    height: 100%;
}

.socialMediaShare {
    top: unset !important;
    bottom: 0 !important;
}

.sb-proprietes-card-infos li:first-child {
    font-size: 1.4rem;
}

.sb-proprietes-card-infos li:nth-child(2) {
    white-space: nowrap;
}

.sb-proprietes-card-thumb {
    background-position: center !important;
}

.pagination li {
    margin: 0 0 !important;
}

.viewFilter {
    cursor: pointer;
}

.thumbView .proprietes-cardH-content {
    display: none;
}

a.viewFilter {
    color: #24b1cf !important;
}

select[name=tri] {
    border: 0.2rem solid #ccc;
    background-color: transparent;
    padding: 1rem 1.3rem;
}

.page-interne {
    padding: 3rem 0 !important;
}

.proprietes-cardH-free-visit {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20rem;
    padding: 1.5rem;
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: #69c3d6;
    z-index: 1;
    -webkit-transform: rotate(-45deg) translateX(-25%) translateY(-50%);
    transform: rotate(-45deg) translateX(-25%) translateY(-50%);
}


/*
 * PHASE 2
 */

.pagination li a {
    width: 100% !important;
    height: 5rem !important;
    border: none !important;
}

.pagination li a:not(.active):hover {
    background-color: #ffffff !important;
}

.page-interne-content {
    padding-left: 0 !important;
}

.btn-single-propriete {
    padding: 0 !important;
}

.proprietes-detail {
    left: 15px !important;
}

.socialMediaShare {
    z-index: 8 !important;
}
