.btn {
    position: relative;
    display: inline-block;
    padding: 1rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: 700;
    font-size: 1.4rem;

    &-gray {
        position: relative;
        border: 0.2rem solid $gray-light;
        color: $gray-light;
        background-color: transparent;
        z-index: 2;

        &:hover {
            color: white;
            background-color: $gray-light;
        }
    }

    &-blue {
        position: relative;
        border: 0.2rem solid $main;
        color: $main;
        background-color: transparent;
        z-index: 2;

        &:hover {
            color: white;
            background-color: $main;
        }
    }

    &-white {
        position: relative;
        border: 0.2rem solid white;
        color: white;
        background-color: transparent;
        z-index: 2;

        &:hover {
            color: $main;
            background-color: white;
        }
    }

    &-google,
    &-gstreet,
    &-print {
        position: relative;
        display: block;
        width: 100%;
        padding: 1.5rem 1.5rem 1.5rem 4.5rem;
        color: white;
        text-align: left;

        i {
            position: absolute;
            top: 50%;
            left: 1.5rem;
            font-size: 2rem;
            transform: translateY(-50%);
        }

        &:hover {
            opacity: 0.9;
            color: white;
        }
    }

    &-google {
        background-color: $main;
    }

    &-gstreet {
        background-color: $black;
    }

    &-print {
        background-color: $gray;
    }
}

.btn-single-propriete {
    margin-bottom: 3rem;
    padding: 0 1.5rem;

    @media(min-width: $lg) {
        .row{
            :nth-child(2){
                padding: 0 29px;
            }
        }
    }
}

button,
input[type="submit"] {
    outline: none !important;

    &:hover {
        cursor: pointer;
    }
}
