.footer{
  &-main{
    position: relative;
    display: block;
    padding: 6rem;
    font-size: 1.4rem;
    background-color: $main;
    color: white;
    @media(max-width: $sm) {
      padding: 6rem 1.5rem;
    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        display: block;
        &:first-child{
          font-size: 1.6rem;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 1.5rem;
          padding-bottom: 1.5rem;
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
          @media(max-width: $md){
            margin-top: 3rem;
          }
        }
        a{
          display: inline-block;
          color: white;
          padding: .5rem 0;
          &:hover{
            color: $black;
          }
        }
      }
    }
    .map{
      max-width: 25rem;
      margin-bottom: 3rem;
    }
    .address{
      a{
        color: white;
        &:hover{
          color: $black;
        }
      }
    }
  }
}

.copyright{
  border-top: 0.2rem solid rgba(0, 0, 0, 0.15);
  padding: 3rem 6rem;
  background-color: $main;
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.2px;
  @media(max-width: $md) {
    text-align: center;
  }
  @media(max-width: $sm) {
    padding: 3rem 0;
  }
}