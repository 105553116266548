.quatre-cent-quatre{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: $main;
  &-content{
    position: relative;
    display: block;
    span{
      display: block;
      font-size: 10vw;
      font-weight: 700;
      text-align: center;
      color: white;
      @media(max-width: $md) {
        font-size: 15vw;
      }
      @media(max-width: $xs) {
        font-size: 30vw;
      }
    }
    .btn{
      margin-top: 3rem;
    }
  }
}