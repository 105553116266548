.message-error {
  display: block;
  padding: 1.5rem;
  color: $red;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  border: 0.1rem solid $red;
  i {
    margin-right: 1.5rem;
  }
}

.message-warning {
  display: block;
  padding: 1.5rem;
  color: $yellow;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  border: 0.1rem solid $yellow;
  i {
    margin-right: 1.5rem;
  }
}

.message-success {
  display: block;
  padding: 1.5rem;
  color: $green;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  border: 0.1rem solid $green;
  i {
    margin-right: 1.5rem;
  }
}