//IMPORT FONT
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');


//Title

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4
h5, .h5,
h6, .h6 {
  line-height: 1.5;
  font-weight: 700;
  margin: 0 0 1.5rem 0;
}

body{
  font-family: 'Lato', sans-serif;
}

//LINK
a {
  transition: $three-sec $anim-cubic;
  &:hover,
  &:active,
  &:link,
  &:focus {
    transition: $three-sec $anim-cubic;
  }
}
.single-prop-details-title{
  display: block;
  width: 100%;
  font-size: 1.8rem;
  background-color: $black;
  padding: 1.5rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 6rem;
  margin-bottom: 3rem;
  text-align: left;
}
//Title
.pc-title{
  display: block;
  padding: 1.5rem;
  margin-top: 3rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $main;
  color: white;
}
.courtier_title{
  position: relative;
  display: block;
  width: 100%;
  &:first-child{
    margin-top: 0;
  }
}